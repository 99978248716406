import video from './Video_Aether.mp4';
import './video.css';
import logo from './Logo.png';
// import Descripcion from './descripcion.jsx';
function Video() {
  return (
    <div className='video-container'>
      <img className='Logo' src={logo}/>
      <video className='video' src={video} autoPlay muted loop/>

    </div>
  );
}

export default Video;