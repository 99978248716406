import Video from './Components/video.jsx';
import './Components/video.css';
import TypingEffect from './Components/descripcion.jsx'
function App() {
  return (
    <div >
      <Video />
      <TypingEffect />

    </div>
  );
}

export default App;
