import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import './descripcion.css';

function TypingEffect() {
  const el = useRef(null); // Reference for the element to apply the effect

  useEffect(() => {
    const options = {
      strings: [
        "Pronto en latinoamérica te traeremos la mejor tecnología en desarrollo web del <span class='highlight'>MUNDO</span>.",
      ],
      typeSpeed: 50, // Speed of typing
      backSpeed: 0, // Speed of backspacing
      loop: false, // Enable looping
      smartBackspace: false,
    };

    const typed = new Typed(el.current, options);

    return () => {
      typed.destroy(); // Cleanup effect on component unmount
    };
  }, []);

  return (
    <div className='typing-effect-container'>
      <span className="typing-text" ref={el} /> {/* This span will hold the typing effect */}
    </div>
  );
}

export default TypingEffect;
